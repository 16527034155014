<template>
  <div>
    <div id="Guide">
      <div class="top">
        <h1 class="title">GUIDE</h1>
        <v-tabs v-model="selectedTab">
          <v-tab v-for="tab in tabs" :key="tab.index" class="menu">
            <p>{{ tab.name }}</p>
          </v-tab>
        </v-tabs>
      </div>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item v-for="tab in tabs" :key="tab.index">
          <!-- (Tab 0) -->
          <div v-if="tab.index === 0">
            <div class="row guide_detail_content pt-5">
              <div class="col-12">
                <template
                  v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
                >
                  <h3>세번째 공간은?</h3>
                  <p class="mt-2">
                    세번째 공간은 디지털 아트 NFT를 감상하고 직접 소장할 수 있는
                    공간입니다. 웹사이트를 통해 다양한 작가들의 작품을 전시하고
                    있으며 원화 또는 암호화폐를 이용하여 좋아하는 작품을 구매할
                    수 있습니다.
                  </p>
                  <h3 class="mt-5">NFT란?</h3>
                  <p class="mt-2">
                    작가의 작품을 사이트에 등록하면 Non-Fungible Token (NFT,
                    대체불가토큰) 라는 블록체인 상에 기록된 ‘디지털 증표‘를
                    동시에 발행하게 됩니다. 이 토큰을 보유할 경우 구매자가
                    ‘증명‘이 되어 소유권을 주장할 수 있습니다.
                  </p>
                  <h3 class="mt-5">NFT 구매와 보관</h3>
                  <p class="mt-2">
                    NFT는 블록체인상에 존재하는 토큰이기때문에, 암호화폐
                    ‘지갑‘에 보관해야 합니다. 지갑은 블록체인 네트워크에서 직접
                    생성하거나 관련 서비스사들이 개발한 소프트웨어를 이용할 수
                    있으며 디지털 아트 구매 시 반드시 연결해야 해당 지갑에 NFT가
                    전송됩니다. 작품 구매는 원화 결제 또는 암호화폐 결제에 따라
                    연동 방식에 차이가 있으며, 자세한 사항은 ‘결제 가이드’란
                    확인 바랍니다.
                  </p>
                  <h3 class="mt-5">클레이튼과 이더리움</h3>
                  <p class="mt-2">
                    세번째 공간은 현재 두개의 다른 블록체인 네트워크를 이용해
                    NFT를 발행합니다. 각 블록체인에서 발행된 NFT는 서로 다른
                    성질을 가지고 있어 분류가 필요한데, 작품 카드 하단 우측에
                    보이는 로고 또는 필터 기능을 통해 구분을 하고 있습니다.
                    클레이튼, 이더리움 보관 또한 서로 다른 지갑이 필요합니다.
                    세번째 공간은 클레이튼은 ‘KLIP 지갑’, 이더리움은
                    ‘메타마스크’ 라는 앱을 지원하며 작품을 구매하실 때 반드시
                    NFT를 발행한 블록체인 네트워크에 맞는 지갑을 연결해야 NFT를
                    소유하실 수 있습니다. 각 지갑의 연결 방법은 이더리움일 경우
                    ‘메타마스크 연결’, 클레이튼은 ‘클립 지갑 연결’ 가이드를
                    참조바랍니다.
                  </p>
                </template>
                <template v-else>
                  <h3>3space Art</h3>
                  <p class="mt-2">
                    3space art is a place to discover and collect digital art by
                    emerging artists. We provide an interactive interface to
                    find various artwork created by digital native artists which
                    can be collected using cryptocurrencies and fiat (Korean
                    Won). This is possible because we issue Non-fungible tokens
                    (NFTs) that correspond to each art piece, making it unique,
                    tradable and compatible.
                  </p>
                  <h3 class="mt-5">What are NFTs?</h3>
                  <p class="mt-2">
                    NFTs are digital certificates that prove the authenticity of
                    each artwork. When an artist makes a new drop, a token is
                    minted and deployed on the blockchain network so that
                    ownership can be claimed and recognized.
                  </p>
                  <h3 class="mt-5">Collecting and storing NFTs</h3>
                  <p class="mt-2">
                    Since NFTs are digital assets that reside on the blockchain,
                    they are usually stored in cryptocurrency wallets. Wallets
                    are needed for transactions and we support different
                    third-party programs and services. For more information,
                    please find our transaction guideline.
                  </p>
                  <h3 class="mt-5">Klaytn and Ethereum</h3>
                  <p class="mt-2">
                    3space Art supports multiple blockchain networks. We
                    currently mint NFTs on the Ethereum and Klaytn network
                    depending on the artist or artwork. Make sure to confirm
                    where the NFT was issued by checking the logo on the bottom
                    right of the cover page, or on the main page. Each network
                    requires a different cryptocurrency wallet. For Klaytn, we
                    support KLIP wallets, and for Ethereum we recommend using
                    Metamask or any of the wallets on Walletconnect. For details
                    on the wallet app, please find our Metamask and Klip guide.
                  </p>
                </template>
              </div>
            </div>
          </div>

          <!-- (Tab 1) -->
          <div
            v-if="tab.index === 1"
            class="guide"
            style="padding-bottom: 80px"
          >
            <div class="row d-flex mt-5">
              <b-img
                src="/img/threespace/guide/ai/ai-1.png"
                class="w-100"
              ></b-img>
            </div>
            <div class="guide_detail_content pt-5">
              <div>
                <h3 class="title nmt">{{ $t("guide.ai.title") }}</h3>
                <p class="text-middle">
                  {{ $t("guide.ai.step1") }}<br />
                  {{ $t("guide.ai.step2") }}<br />
                  {{ $t("guide.ai.step3") }}
                </p>
              </div>
            </div>
            <div class="guide_detail_content">
              <div>
                <h3 class="title">{{ $t("guide.ai.prompt.title") }}</h3>
                <div class="d-flex mt-5">
                  <b-img
                    v-if="
                      activeLanguage !== undefined && activeLanguage === 'ko'
                    "
                    src="/img/threespace/guide/ai/ai-2.png"
                    class="w-100"
                  ></b-img>
                  <b-img
                    v-else
                    src="/img/threespace/guide/ai/ai-2-en.png"
                    class="w-100"
                  ></b-img>
                </div>
                <ul class="text-small">
                  <li>
                    <p>{{ $t("guide.ai.prompt.description1") }}</p>
                  </li>
                  <li>
                    <p>{{ $t("guide.ai.prompt.description2") }}</p>
                  </li>
                  <li>
                    <p>{{ $t("guide.ai.prompt.description3") }}</p>
                  </li>
                  <li>
                    <p>{{ $t("guide.ai.prompt.description4") }}</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="guide_detail_content">
              <div>
                <div>
                  <b-img
                    center
                    src="/img/threespace/guide/ai/ai-3.png"
                    class="w-50 bm"
                  ></b-img>
                </div>
                <p class="text-big">
                  {{ $t("guide.ai.prompt.guide.description") }}
                </p>
              </div>
            </div>
            <div class="guide_detail_content">
              <div>
                <h3 class="title">{{ $t("guide.ai.prompt.howToWrite") }}</h3>
                <div class="d-flex justify-content-center mt-5">
                  <b-img
                    src="/img/threespace/guide/ai/ai-4.png"
                    class="w-100"
                  ></b-img>
                </div>
                <p class="text-big">
                  {{ $t("guide.ai.prompt.howToWriteDescription") }}
                </p>
                <div class="d-flex justify-content-center">
                  <b-img
                    src="/img/threespace/guide/ai/ai-5.png"
                    class="w-100 bm"
                  ></b-img>
                </div>
                <p class="text-big">
                  {{ $t("guide.ai.prompt.howToWriteDescription2") }}
                </p>
              </div>
            </div>
            <div class="guide_detail_content">
              <div class="section">
                <h3 class="title">{{ $t("guide.ai.promptStructure") }}</h3>
                <p class="text-big">
                  {{ $t("guide.ai.style.title") }}<br />
                  {{ $t("guide.ai.style.description") }}
                </p>
              </div>
              <div class="section">
                <p class="text-big">
                  {{ $t("guide.ai.quality.title") }}<br />
                  {{ $t("guide.ai.quality.description") }}
                </p>
              </div>
              <div class="section">
                <p class="text-big">
                  {{ $t("guide.ai.repeatingWords.title") }}<br />
                  {{ $t("guide.ai.repeatingWords.description") }}
                </p>
              </div>
              <div class="section">
                <p class="text-big">
                  {{ $t("guide.ai.uniqueTerms.title") }}<br />
                  {{ $t("guide.ai.uniqueTerms.description") }}
                </p>
              </div>
            </div>
            <div class="quote">{{ $t("guide.ai.quote") }}</div>
          </div>

          <!-- (Tab 2) -->
          <div v-if="tab.index === 2" style="padding-bottom: 80px">
            <div class="row guide_detail_content pt-5">
              <div class="col-12">
                <h3>메타마스크 지갑연결</h3>
                <p class="pt-3">
                  세번째 공간에서 이더리움 NFT를 구매하기 앞서 다음사항을
                  준비해야합니다.
                </p>
                <p>
                  1. 메타마스크 설치<br />
                  2. 메타마스크 지갑 생성<br />
                  3. 메타마스크 지갑 연결
                </p>
              </div>
            </div>
            <div class="row d-flex justify-content-center mt-5">
              <div class="col-md-10">
                <video
                  id="guide-metamask"
                  class="w-100"
                  controls
                  controlsList="nodownload"
                  poster="/img/threespace/guide/metamask/metamask_poster.jpg"
                >
                  <source
                    src="/img/threespace/guide/metamask/metamask.mp4"
                    type="video/mp4"
                    playsinline
                  />
                  video 요소를 지원하지 않는 브라우저입니다. 동영상은 다운로드
                  후 볼 수 있습니다.
                </video>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-8 col-sm-12">
                <b-img
                  center
                  src="/img/threespace/guide/metamask/install/1.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/install/2.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/install/3.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/install/4.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/install/5.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/install/6.png"
                  class="w-75"
                ></b-img>
              </div>
              <div class="col-md-8 col-sm-12">
                <b-img
                  center
                  src="/img/threespace/guide/metamask/create/1.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/create/2.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/create/3.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/create/4.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/create/5.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/create/6.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/create/7.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/create/8.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/create/9.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/create/10.png"
                  class="w-75"
                ></b-img>
              </div>
              <div class="col-md-8 col-sm-12">
                <b-img
                  center
                  src="/img/threespace/guide/metamask/connect/1.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/connect/2.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/connect/3.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/connect/4.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/connect/5.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/connect/6.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/metamask/connect/7.png"
                  class="w-75"
                ></b-img>
              </div>
            </div>
          </div>

          <!-- (Tab 3) -->
          <div v-if="tab.index === 3" style="padding-bottom: 80px">
            <div class="row guide_detail_content pt-5">
              <div class="col-12">
                <h3>클립 지갑 연결</h3>
                <p class="pt-3">
                  세번째 공간에서 모바일 카카오톡 클립 지갑을 지원합니다.<br />
                  클립 NFT를 구매하기 앞서 다음사항을 확인해주세요.
                </p>
                <p>
                  1. 카카오톡 - 클립 서비스 실행하기<br />
                  2. 클립 지갑연결하기
                </p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-8 col-sm-12 text-center pt-5">
                <b-img
                  center
                  src="/img/threespace/guide/klip/2.png"
                  class="w-75"
                ></b-img>
                <p class="mt-2">
                  모바일 카카오톡 클립 서비스 : https://klipwallet.com/
                </p>
                <b-img
                  center
                  src="/img/threespace/guide/klip/3.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/klip/4.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/klip/5.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/klip/6.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/klip/7.png"
                  class="w-75"
                ></b-img>
                <b-img
                  center
                  src="/img/threespace/guide/klip/8.png"
                  class="w-75"
                ></b-img>
              </div>
            </div>
          </div>

          <!-- (Tab 4) -->
          <div v-if="tab.index === 4" style="padding-bottom: 80px">
            <div class="row guide_detail_content pt-5">
              <div class="col-12">
                <template
                  v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
                >
                  <h3>네오핀 지갑 연결</h3>
                  <p class="pt-3">
                    세번째공간은 네오핀 지갑을 지원합니다.<br />
                    아래의 내용을 확인해주세요.
                  </p>
                </template>
                <template v-else>
                  <h3>Connecting NEOPIN Wallet</h3>
                  <p class="pt-3">Please see the following details.</p>
                </template>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-8 col-sm-12 text-center">
                <template
                  v-if="activeLanguage !== undefined && activeLanguage === 'ko'"
                >
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_1.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_2.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_3.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_4.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_5.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_6.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_7.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_8.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_9.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_10.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_11.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_12.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_13.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_14.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_15.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_ko_16.jpg"
                    class="w-75"
                  ></b-img>
                </template>
                <template v-else>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_1.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_2.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_3.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_4.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_5.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_6.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_7.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_8.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_9.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_10.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_11.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_12.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_13.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_14.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_15.jpg"
                    class="w-75"
                  ></b-img>
                  <b-img
                    center
                    src="/img/threespace/guide/neopin/neopin_en_16.jpg"
                    class="w-75"
                  ></b-img>
                </template>
              </div>
            </div>
          </div>

          <!-- (Tab 5) -->
          <div
            class="guide_detail_content"
            v-if="tab.index === 5"
            style="padding-bottom: 80px"
          >
            <div class="row pt-5">
              <div class="col-12">
                <b-img
                  fluid-grow
                  src="/img/threespace/guide/payment/1.png"
                ></b-img>
              </div>
            </div>
            <div class="row d-flex justify-content-center mt-5 pt-5">
              <div class="col-md-10">
                <video
                  id="guide-payment"
                  class="w-100"
                  controls
                  controlsList="nodownload"
                  poster="/img/threespace/guide/payment/payment_poster.jpg"
                >
                  <source
                    src="/img/threespace/guide/payment/payment.mp4"
                    type="video/mp4"
                    playsinline
                  />
                  video 요소를 지원하지 않는 브라우저입니다. 동영상은 다운로드
                  후 볼 수 있습니다.
                </video>
              </div>
            </div>
            <div class="row d-flex justify-content-center mt-5 pt-5">
              <div class="col-md-6 col-sm-6">
                <b-img
                  center
                  src="/img/threespace/guide/payment/2.png"
                  class="w-75"
                ></b-img>
              </div>
              <div
                class="content_text_box col-md-4 col-sm-6 text-center align-self-center py-3"
              >
                <h3>갤러리에서 원하는 작품을 클릭해주세요.</h3>
              </div>
            </div>
            <div class="row d-flex justify-content-center mt-5">
              <div class="col-md-6 col-sm-6">
                <b-img
                  center
                  src="/img/threespace/guide/payment/3.png"
                  class="w-75"
                ></b-img>
              </div>
              <div
                class="content_text_box col-md-4 col-sm-6 text-center align-self-center py-3"
              >
                <h3>
                  아래 약관 동의에 체크해주시고<br /><b>결제하기</b>를
                  클릭해주세요.
                </h3>
              </div>
            </div>
            <div class="row d-flex justify-content-center mt-5">
              <div class="col-md-6 col-sm-6">
                <b-img
                  center
                  src="/img/threespace/guide/payment/4.png"
                  class="w-75"
                ></b-img>
              </div>
              <div
                class="content_text_box col-md-4 col-sm-6 text-center align-self-center py-3"
              >
                <h3>
                  전체 약관 동의 후 다음을 클릭하신 뒤,<br /><b
                    >입금기한을 꼭 확인</b
                  >해주세요!
                </h3>
                <p>*해당 기한까지 입금되어야 구매가 완료됩니다.</p>
                <h3><b>결제완료</b>를 눌러주세요.</h3>
              </div>
            </div>
            <div class="row mt-5 pt-5">
              <div class="content_text_box col-12 text-center">
                <h3>
                  구매를 완료하신 작품을<br />
                  상단의 프로필, 개인 계정을 클릭하시면<br />
                  <b>컬렉션 및 결제 내역</b>에서 확인할 수 있습니다.
                </h3>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
export default {
  name: "Guide",
  metaInfo() {
    return {
      title: this.$t("meta.guide.title") + " | 3space Art",
      meta: [
        {
          vmid: "title",
          name: "title",
          content: this.$t("meta.guide.title") + " | 3space Art",
        },
        {
          vmid: "description",
          name: "description",
          content: this.$t("meta.guide.description"),
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.$t("meta.guide.title") + " | 3space Art",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.$t("meta.guide.description"),
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.origin + this.$route.path,
        },
      ],
    };
  },
  data() {
    return {
      selectedTab: 0,
      tabs: [
        { index: 0, name: "3space Art" },
        { index: 1, name: "A.I." },
        { index: 2, name: this.$t("guide.title.metamask") },
        { index: 3, name: this.$t("guide.title.klip") },
        { index: 4, name: this.$t("guide.title.neopin") },
        { index: 5, name: this.$t("guide.title.payment") },
      ],
    };
  },
  computed: {
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  methods: {
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
  },
};
</script>
